import * as React from "react";
import { useEffect, useState } from "react";

import * as _ from "lodash";

import { FormControl, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

import { IClient, getUserClients } from "../../../Adapters/clientAdapter";
import ErrorResults, { IFluentValidationException } from "../../../Components/Dialog/ErrorResults";
import Loading from "../../../Components/Loading";
import UploadButton from "../../../Components/UploadButton";

const RESULTS_LIMIT = 5_000;

export interface IFileDetails {
  name: string;
  contents: string;
  clientCode: string;
}

interface IBatchUploadDialogContentProps {
  errorMessages?: string[] | IFluentValidationException[];
  onDetailsChange: (details: IFileDetails | undefined) => void;
  value?: IFileDetails;
}
const fileTypes: string[] = ["text/csv", ".csv", "application/vnd.ms-excel"];

/**
 * Renders the component used to perform the batch import request.
 */
function BatchUploadDialogContent(props: IBatchUploadDialogContentProps) {
  const { errorMessages } = props;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [clients, setClients] = useState<IClient[]>([]);

  useEffect(() => {
    async function getClients() {
      const response = await getUserClients();
      setClients(response);
    }
    void getClients();
  }, []);

  /**
   * Handles errors that are returned from selecting the file for upload.
   */
  function handleUploadError(error: string): void {
    setErrorMessage(error);
  }

  /**
   * Handles the event when a file has been successfully chosen to upload.
   */
  function handleOnUpload(file: File) {
    props.onDetailsChange(undefined);
    if (!fileTypes.includes(file.type) && file.name.indexOf(".csv") === -1) {
      setErrorMessage("Invalid file type");
      return;
    }
    const reader = new FileReader();
    reader.onload = function () {
      const text = reader.result as string;

      let results: string[] = [];
      const rows = text.split("\n");
      rows.forEach((row: string) => {
        const values = row.split(",");
        if (values.length > 0 && values[0] !== "") {
          const value = values[0].replace("\r", "").replace("\n", "");
          results.push(value);
        }
      });

      if (results.length === 0) {
        setErrorMessage("No results found");
      } else {
        results = _.uniq(results);
        let error = "";
        if (results.length > RESULTS_LIMIT) {
          results = results.slice(0, RESULTS_LIMIT);
          error = `Limit exceeded, only the first ${RESULTS_LIMIT} results will be processed.`;
        }
        setErrorMessage(error);
        props.onDetailsChange({
          clientCode: props.value?.clientCode ?? "",
          contents: text,
          name: file.name.replace(/\.[^/.]+$/, ""),
        } as IFileDetails);
      }
    };
    reader.readAsText(file);
  }

  let errorDisplay = <></>;
  if (errorMessages && errorMessages.length) {
    errorDisplay = <ErrorResults errors={errorMessages} />;
  }
  const handleClientUpdate = (event: SelectChangeEvent<string>) => {
    props.onDetailsChange({
      ...props.value,
      clientCode: event.target.value,
    } as IFileDetails);
  };

  if (!clients) {
    return <Loading />;
  }
  return (
    <>
      <Alert severity="warning">
        This action cannot be cancelled. Please validate your data before upload. Docutech does not validate data
        uploaded to these fields. Data integrity is the responsibility of the user.
      </Alert>
      <Box
        sx={{ alignItems: "center", display: "flex", flexDirection: "column", marginBottom: "16px", marginTop: "16px" }}
      >
        <UploadButton onError={handleUploadError} onUpload={handleOnUpload} />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Client</InputLabel>
          <Select
            fullWidth
            label="Client Code"
            value={props.value?.clientCode ?? ""}
            onChange={handleClientUpdate}
            sx={{ mb: 2, mt: 1 }}
            variant="standard"
          >
            {clients.map((field) => (
              <MenuItem key={field.id} value={field.name}>
                {field.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <div className="batch-upload" style={{ marginBottom: "16px" }}></div>
      {errorMessage !== undefined && <div>{errorMessage}</div>}
      {errorDisplay}
    </>
  );
}

export default BatchUploadDialogContent;
