import React, { ReactElement, useEffect, useState } from "react";

import _ from "lodash";
import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Box from "@mui/material/Box";

import { RootState } from "App/Store";

import { baseSearchCriteria } from "Common/EVaultAppConstants";

import FilterBar from "Components/SearchBar/FilterBar";

import { HTMLElementTypes } from "Types/EVaultAppTypes";

import {
  initialClosedLoansSearchBarParams,
  resetSearch,
  updateSearchBarParams,
} from "Features/Vault/ClosedLoans/ClosedLoansSlice";

import { clientDefinedDataFieldsSelector } from "../../Profile/ProfileSlice";
import PackageClientDefinedDataFieldUpload from "../PackageClientDefinedDataFieldUpload";
import { executeSearch } from "./closedLoansService";

const closedLoansFilters = [
  ...baseSearchCriteria,
  {
    label: "search",
    name: "searchKey",
    options: [
      {
        apiParamValue: "loanNumber",
        description: "Loan Number",
      },
      {
        apiParamValue: "mortgageIdentificationNumber",
        description: "MIN",
      },
      {
        apiParamValue: "namedSigner1LastName",
        description: "Signer Last Name",
      },
    ],
    type: HTMLElementTypes.DROPDOWN,
  },
];

const mapStateToProps = (state: RootState) => ({
  channels: state.channels,
  clientDefinedDataFields: state.clientDefinedDataFields.data,
  clientDefinedDataFieldsEnabled: clientDefinedDataFieldsSelector(state),
  searchBarParams: state.closedLoans.searchBarParams,
});

const connector = connect(mapStateToProps);
type TypesFromRedux = ConnectedProps<typeof connector>;

export function ClosedLoansFilterBar(props: TypesFromRedux): ReactElement {
  const { channels, clientDefinedDataFields, clientDefinedDataFieldsEnabled, searchBarParams, dispatch } = props;

  const [searchCriteria, setSearchCriteria] = useState<any[]>(closedLoansFilters);

  useEffect(() => {
    const filters = closedLoansFilters.slice(0);
    const searchTermCriteria = filters[4];
    if (!searchTermCriteria) {
      setSearchCriteria(filters);
      return;
    }
    const options = searchTermCriteria.options;
    if (!options) {
      setSearchCriteria(filters);
      return;
    }
    searchTermCriteria.options = options.concat(
      clientDefinedDataFields
        .filter((cdf) => cdf.enabled)
        .map((cdf) => {
          return {
            apiParamValue: `clientDefinedDataField:${cdf.id}`,
            description: cdf.name,
          };
        })
    );
    if (channels.channels.length > 1) {
      filters.push({
        name: "divider",
        type: HTMLElementTypes.DIVIDERBLOCK,
      });
      filters.push({
        label: "Channels",
        name: "channelIds",
        options: channels.channels.map((channel) => {
          return {
            apiParamValue: channel.id.toString(),
            description: channel.name,
          };
        }),
        type: HTMLElementTypes.DROPDOWNCHECKLIST,
      });
    }
    setSearchCriteria(filters);
  }, [channels.isLoading, channels.channels, clientDefinedDataFields]);

  function handleReset() {
    dispatch(resetSearch());
  }

  function handleSubmit() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(executeSearch());
  }

  function handleCriteriaChange(value: any) {
    dispatch(updateSearchBarParams(value));
  }

  const displayResetButton = !_.isEqual(initialClosedLoansSearchBarParams, searchBarParams);

  return (
    <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
      <FilterBar
        displayResetButton={displayResetButton}
        onCriteriaChange={handleCriteriaChange}
        onReset={handleReset}
        onSubmit={handleSubmit}
        searchCriterias={searchCriteria}
        searchParams={searchBarParams}
      />
      {clientDefinedDataFieldsEnabled && <PackageClientDefinedDataFieldUpload />}
    </Box>
  );
}

export default connector(ClosedLoansFilterBar);
