import * as React from "react";
import { useEffect, useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import MuiTooltip from "@mui/material/Tooltip";

import { updateClientDefinedDataFields } from "../clientDefinedDataFieldsAdapters";
import BatchUploadDialogContent, { IFileDetails } from "./BatchUploadDialogContent";
import TemplateDownload from "./TemplateDownload";

/**
 * This component renders the package client defined upload component
 */
function PackageClientDefinedDataFieldUpload() {
  const [fileDetails, setFileDetails] = useState<IFileDetails | undefined>();
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setFileDetails(undefined);
    setErrorMessages(undefined);
  }, [open]);

  /**
   * When the confirmation is triggered,
   * the request is made to the requested URL to upload the file contents for batch processing.
   */
  async function handleConfirmation() {
    if (fileDetails) {
      setUploading(true);
      setErrorMessages(undefined);
      try {
        const result = await updateClientDefinedDataFields(fileDetails.contents, fileDetails.clientCode);
        if (result.data) {
          console.log("done");
        }
        setOpen(false);
        setUploading(false);
      } catch (e: any) {
        setUploading(false);
        if (e.response.data && e.response.data.length && typeof e.response.data !== "string") {
          setErrorMessages(e.response.data);
        } else if (e.response.data && e.response.data.ErrorMessage === "An unhandled exception occurred") {
          setErrorMessages(["Something went wrong with your request."]);
        } else if (e.response.data && typeof e.response.data === "string") {
          setErrorMessages([e.response.data]);
        } else {
          setErrorMessages([`The upload request failed with a status code ${e.response.status}`]);
        }
      }
    }
  }

  /**
   * This event is triggered whenever the file details have been changed in the upload button.
   */
  function handleFileDetailsChange(details: any) {
    setFileDetails(details);
    setErrorMessages(undefined);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <MuiTooltip title="Upload CSV to add data to Client Defined Fields. These Client Defined Fields are currently only shown on the Closed tab in the eVault.">
        <Button onClick={handleOpen}>Custom Fields</Button>
      </MuiTooltip>
      <Dialog disableEscapeKeyDown open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>Update client defined fields?</DialogTitle>
        <DialogContent dividers>
          <BatchUploadDialogContent
            value={fileDetails}
            errorMessages={errorMessages}
            onDetailsChange={handleFileDetailsChange}
          />
        </DialogContent>

        <DialogActions style={{ display: "flex", justifyContent: "space-between" }}>
          <TemplateDownload />
          <div>
            <Button onClick={handleClose} style={{ marginRight: "4px" }}>
              CANCEL
            </Button>
            <Button
              color="secondary"
              disabled={!fileDetails?.contents || !fileDetails.name || uploading}
              onClick={handleConfirmation}
              variant="contained"
            >
              CONTINUE
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PackageClientDefinedDataFieldUpload;
